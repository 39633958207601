import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1503977, catid=256107, projectId=41427, created=2025-01-07 18:20:52, last-modified=2025-01-07 18:26:30
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/eval/fail)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1503977)
 */
export function postQuizEvalFail(params: model.quiz.IPostQuizEvalFailParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizEvalFailResponse>('/webApi/quiz/eval/fail', {
    data: pick(params, ['quizEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1500741, catid=256107, projectId=41427, created=2024-12-12 16:50:05, last-modified=2024-12-30 14:46:20
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/chatLogFeedback)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1500741)
 */
export function postQuizChatLogFeedback(params: model.quiz.IPostQuizChatLogFeedbackParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizChatLogFeedbackResponse>('/webApi/quiz/chatLogFeedback', {
    data: pick(params, ['id', 'userFeedback']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1501197, catid=256107, projectId=41427, created=2024-12-17 16:32:19, last-modified=2024-12-17 17:02:59
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/getCommonPreset)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1501197)
 */
export function postQuizGetCommonPreset(params: model.quiz.IPostQuizGetCommonPresetParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizGetCommonPresetResponse>('/webApi/quiz/getCommonPreset', {
    data: pick(params, ['id']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1503071, catid=256107, projectId=41427, created=2024-12-24 16:55:28, last-modified=2024-12-30 14:46:37
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/dialogue/behavior/record)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1503071)
 */
export function postQuizDialogueBehaviorRecord(
  params: model.quiz.IPostQuizDialogueBehaviorRecordParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.quiz.IPostQuizDialogueBehaviorRecordResponse>('/webApi/quiz/dialogue/behavior/record', {
    data: pick(params, ['id', 'behaviorType']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1499546, catid=256107, projectId=41427, created=2024-12-09 16:19:28, last-modified=2024-12-18 10:29:47
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/createEval)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1499546)
 */
export function postQuizCreateEval(params: model.quiz.IPostQuizCreateEvalParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizCreateEvalResponse>('/webApi/quiz/createEval', {
    data: pick(params, ['prioritizeWaitingEval']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1499764, catid=256107, projectId=41427, created=2024-12-10 16:46:00, last-modified=2024-12-18 16:35:32
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/streamChat)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1499764)
 */
export function postQuizStreamChat(params: model.quiz.IPostQuizStreamChatParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizStreamChatResponse>('/webApi/quiz/streamChat', {
    data: pick(params, ['quizEvalId', 'userInput', 'retryGenerateFlag']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1500637, catid=256107, projectId=41427, created=2024-12-12 02:29:40, last-modified=2024-12-12 02:31:26
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/setRemindStatus)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1500637)
 */
export function postQuizSetRemindStatus(params: model.quiz.IPostQuizSetRemindStatusParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizSetRemindStatusResponse>('/webApi/quiz/setRemindStatus', {
    data: pick(params, ['idList']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1499523, catid=256107, projectId=41427, created=2024-12-09 14:59:28, last-modified=2024-12-16 15:01:39
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/listEvalSubjectConfig)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1499523)
 */
export function postQuizListEvalSubjectConfig(
  params: model.quiz.IPostQuizListEvalSubjectConfigParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.quiz.IPostQuizListEvalSubjectConfigResponse>('/webApi/quiz/listEvalSubjectConfig', {
    data: pick(params, ['onlyIncompleteSubjects']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1499525, catid=256107, projectId=41427, created=2024-12-09 15:18:49, last-modified=2024-12-30 15:03:53
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/getEvalDetail)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1499525)
 */
export function postQuizGetEvalDetail(params: model.quiz.IPostQuizGetEvalDetailParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizGetEvalDetailResponse>('/webApi/quiz/getEvalDetail', {
    data: pick(params, ['quizEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1499858, catid=256107, projectId=41427, created=2024-12-10 20:57:31, last-modified=2024-12-26 17:06:54
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/getRunningEval)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1499858)
 */
export function postQuizGetRunningEval(params: model.quiz.IPostQuizGetRunningEvalParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizGetRunningEvalResponse>('/webApi/quiz/getRunningEval', {
    data: params,
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1499515, catid=256107, projectId=41427, created=2024-12-09 14:47:25, last-modified=2024-12-12 14:14:45
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/getEvalList)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1499515)
 */
export function postQuizGetEvalList(params: model.quiz.IPostQuizGetEvalListParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizGetEvalListResponse>('/webApi/quiz/getEvalList', {
    data: pick(params, ['pageNum', 'pageSize']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1500630, catid=256107, projectId=41427, created=2024-12-12 02:18:50, last-modified=2024-12-26 15:01:26
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/getProgress)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1500630)
 */
export function postQuizGetProgress(params: model.quiz.IPostQuizGetProgressParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizGetProgressResponse>('/webApi/quiz/getProgress', {
    data: params,
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1499885, catid=256107, projectId=41427, created=2024-12-11 01:48:57, last-modified=2024-12-20 10:31:57
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/chatWithModel)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1499885)
 */
export function postQuizChatWithModel(params: model.quiz.IPostQuizChatWithModelParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizChatWithModelResponse>('/webApi/quiz/chatWithModel', {
    data: pick(params, ['modelId', 'input']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1500539, catid=256107, projectId=41427, created=2024-12-11 14:55:29, last-modified=2024-12-23 16:54:48
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/quiz/enterQuiz)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1500539)
 */
export function postQuizEnterQuiz(params: model.quiz.IPostQuizEnterQuizParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.quiz.IPostQuizEnterQuizResponse>('/webApi/quiz/enterQuiz', {
    data: pick(params, ['quizEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
