import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1463622, catid=251501, projectId=41427, created=2024-08-27 16:15:17, last-modified=2024-11-08 15:51:00
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/ratingRanking)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463622)
 */
export function postInstanceEvalRatingRanking(
  params: model.instanceEval.IPostInstanceEvalRatingRankingParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instanceEval.IPostInstanceEvalRatingRankingResponse>('/webApi/instanceEval/ratingRanking', {
    data: pick(params, ['themeId', 'topNum']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1490078, catid=250712, projectId=41427, created=2024-11-14 10:47:59, last-modified=2024-11-18 11:04:36
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/detail)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1490078)
 */
export function postInstanceEvalDetail(params: model.instanceEval.IPostInstanceEvalDetailParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.instanceEval.IPostInstanceEvalDetailResponse>('/webApi/instanceEval/detail', {
    data: pick(params, ['instanceEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1488331, catid=250712, projectId=41427, created=2024-11-06 17:03:53, last-modified=2024-11-06 18:12:43
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/complete)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1488331)
 */
export function postInstanceEvalComplete(params: model.instanceEval.IPostInstanceEvalCompleteParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.instanceEval.IPostInstanceEvalCompleteResponse>('/webApi/instanceEval/complete', {
    data: pick(params, ['instanceEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1479408, catid=250712, projectId=41427, created=2024-09-27 16:26:35, last-modified=2024-09-27 16:28:21
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/switchModelReminder/close)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1479408)
 */
export function postInstanceEvalSwitchModelReminderClose(
  params: model.instanceEval.IPostInstanceEvalSwitchModelReminderCloseParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instanceEval.IPostInstanceEvalSwitchModelReminderCloseResponse>('/webApi/instanceEval/switchModelReminder/close', {
    data: pick(params, ['themeId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1463505, catid=250712, projectId=41427, created=2024-08-27 14:47:10, last-modified=2024-11-04 14:35:04
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/myThemeInfo)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463505)
 */
export function postInstanceEvalMyThemeInfo(
  params: model.instanceEval.IPostInstanceEvalMyThemeInfoParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instanceEval.IPostInstanceEvalMyThemeInfoResponse>('/webApi/instanceEval/myThemeInfo', {
    data: pick(params, ['themeId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1460366, catid=250712, projectId=41427, created=2024-08-23 15:28:23, last-modified=2024-11-06 16:32:27
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/dispatch)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1460366)
 */
export function postInstanceEvalDispatch(params: model.instanceEval.IPostInstanceEvalDispatchParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.instanceEval.IPostInstanceEvalDispatchResponse>('/webApi/instanceEval/dispatch', {
    data: pick(params, ['themeId', 'config']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1463717, catid=250712, projectId=41427, created=2024-08-27 17:07:58, last-modified=2024-08-27 17:26:27
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/chat)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463717)
 */
export function postInstanceEvalChat(params: model.instanceEval.IPostInstanceEvalChatParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.instanceEval.IPostInstanceEvalChatResponse>('/webApi/instanceEval/chat', {
    data: pick(params, ['instanceEvalId', 'chatSessionId', 'userInput', 'retryGenerateFlag']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1463732, catid=250712, projectId=41427, created=2024-08-27 17:26:33, last-modified=2024-08-27 17:27:42
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/chatLogFeedback)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463732)
 */
export function postInstanceEvalChatLogFeedback(
  params: model.instanceEval.IPostInstanceEvalChatLogFeedbackParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instanceEval.IPostInstanceEvalChatLogFeedbackResponse>('/webApi/instanceEval/chatLogFeedback', {
    data: pick(params, ['chatLogId', 'userFeedback']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1460528, catid=250712, projectId=41427, created=2024-08-23 16:00:11, last-modified=2024-10-15 15:09:38
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instanceEval/answer)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1460528)
 */
export function postInstanceEvalAnswer(params: model.instanceEval.IPostInstanceEvalAnswerParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.instanceEval.IPostInstanceEvalAnswerResponse>('/webApi/instanceEval/answer', {
    data: pick(params, ['instanceEvalId', 'modelHelpfulLevel', 'userAnswer', 'isModelAnswerAccurate']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
