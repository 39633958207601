import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1460809, catid=251183, projectId=41427, created=2024-08-26 10:31:10, last-modified=2024-08-26 19:38:43
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/adminApi/user/ban)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1460809)
 */
export function postAdminApiUserBan(params: model.adminApi.IPostAdminApiUserBanParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.adminApi.IPostAdminApiUserBanResponse>('/adminApi/user/ban', {
    data: pick(params, ['id']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1500859, catid=256107, projectId=41427, created=2024-12-13 15:36:38, last-modified=2024-12-24 15:46:10
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/adminApi/quiz/reward/config/upload/json)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1500859)
 */
export function postAdminApiQuizRewardConfigUploadJson(
  params: model.adminApi.IPostAdminApiQuizRewardConfigUploadJsonParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.adminApi.IPostAdminApiQuizRewardConfigUploadJsonResponse>('/adminApi/quiz/reward/config/upload/json', {
    data: pick(params, ['quizEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'multipart/form-data' }),
  });
}

/**
 * @desc id=1503037, catid=256107, projectId=41427, created=2024-12-24 15:42:56, last-modified=2024-12-24 15:44:52
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/adminApi/quiz/knowledge/upload/json)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1503037)
 */
export function postAdminApiQuizKnowledgeUploadJson(
  params: model.adminApi.IPostAdminApiQuizKnowledgeUploadJsonParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.adminApi.IPostAdminApiQuizKnowledgeUploadJsonResponse>('/adminApi/quiz/knowledge/upload/json', {
    data: pick(params, ['quizEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'multipart/form-data' }),
  });
}
