import { importAll, ImportAllResult } from '@/utils';
import '@/utils/lxReport';
import Vue from 'vue';
import Vuex from 'vuex';
import './horn';
import main from './main';
import './requestAspect';

const storeModules = importAll(require.context('@/store/modules', true, /^((?!index).)*\.ts$/));
const modules = storeModules.reduce((finalModules: any, storeModule: ImportAllResult) => {
  finalModules[storeModule.camelModuleName.split('/')[0]] = storeModule.module;
  return finalModules;
}, {});

Vue.use(Vuex);
const store = new Vuex.Store({
  ...main,
  modules,
});
// store.dispatch('getUserInfo');

export default store;
