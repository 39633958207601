<template>
  <div class="feedback-components">
    <div class="feedback-img">
      <span @click="feedbackClick">
        <img src="https://p0.meituan.net/llmarena/cccedaa108fae4ba53423ca52a656d023813.png" alt=""/>
      </span>
    </div>
    <mtd-modal v-model="show" title="问题反馈" width="600px" @closed="closed">
      <div class="feedback-modal">
        <div>
          <div class="feedback-modal-top">
            <div class="type">
              <p class="title">
                反馈类型
                <i class="red">*</i>
              </p>
              <div class="type-group">
                <mtd-radio-group v-model="formData.type">
                  <mtd-radio value="MODEL_ENHANCEMENT">大模型榜单</mtd-radio>
                  <mtd-radio value="EVALUATION_SET_ERROR">评测集</mtd-radio>
                  <mtd-radio value="PRODUCT_FEATURE">产品交互</mtd-radio>
                  <mtd-radio value="OTHER">其他</mtd-radio>
                </mtd-radio-group>
              </div>
            </div>
            <div class="content">
              <p class="title">
                反馈内容
                <i class="red">*</i>
              </p>
              <mtd-textarea v-model="formData.content"
                            placeholder="请输入" style="width: 100%;"
                            rows="8" maxlength="800"
                            class="custom-textarea"
              />
            </div>
          </div>
          <div class="contact-information">
            <p class="title">请留下您的联系方式，以便我们联系您</p>
            <mtd-textarea v-model="formData.contactInfo"
                          placeholder="手机号/邮箱" style="width: 100%;"
                          rows="3" maxlength="200"
                          class="custom-textarea"
            />
          </div>
        </div>
        <div slot="footer" class="modal-footer">
          <div class="handleSubmit" @click="handleSubmit">提交反馈</div>
        </div>
      </div>
    </mtd-modal>
  </div>
</template>

<script lang="ts">
import { community } from '@/api';
import { CatchError, Component, Spectra, Weaver } from '@/decorators';
import { cloneDeep } from 'lodash-es';
interface IFormData {
  contactInfo: string;
  content: string;
  type: string;
}

const initForm:IFormData = {
  contactInfo: '',
  content: '',
  type: 'MODEL_ENHANCEMENT',
};
  @Component({ components: { } })
  @Spectra
export default class Feedback extends Weaver() {
    show = false;
    formData: Record<string, any> = cloneDeep(initForm);
    feedbackClick() {
      this.show = true;
      this.formData = {
        ...cloneDeep(initForm),
      };
    }

    closed() {
      this.show = false;
      // this.$destroy();
    }

    @CatchError
    async handleSubmit() {
      if (!this.formData.type) {
        this.$mtd.message.warning('请选择反馈类型！');
        return;
      }

      if (!this.formData.content) {
        this.$mtd.message.warning('请输入反馈内容！');
        return;
      }

      const obj = {
        type: this.formData.type,
        content: this.formData.content,
        contactInfo: this.formData.contactInfo,

      };
      const res = await community.postCommunityFeedbackSave(obj);
      console.log('res', res);
      this.$mtd.message.success('反馈成功！');
      this.show = false;
    }
}
</script>
<style lang="less" scoped>
.feedback-components {
    .feedback-img {
      position: fixed;
      bottom: 50px;
      right: 40px;
      z-index: 210000;
      cursor: pointer;
      img {
          width: 65px;
          height: 65px;
      }
    }

}

.feedback-modal-top {
  display: flex;

}

.type {
  width: 35%;
}
.type-group {
  /deep/.mtd-radio-group {
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 10px;
    align-items: flex-start;
    vertical-align: middle;
    flex-direction: column;
  }

}

/deep/.mtd-radio-checked .mtd-radio-inner {
  border-color: #5829fc;
  background-color: #5829fc;
}
/deep/.mtd-radio:hover .mtd-radio-inner {
  border-color: #5829fc;
}

.content {
  width: 65%
}

.contact-information {
  margin: 20px 0;
}

.handleSubmit {
  width: 100%;
  height:50px;
  border-radius: 10px;
  color: #ffff;
  background-color: #5829fc;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.title {
  margin-bottom: 10px;
  display: inline-flex;
  align-items: baseline; /* 确保子元素基线对齐 */
  font-size: 14px; /* 设置文字的基础字体大小 */
  color:#595959;
  .red {
    font-size: 1.2em; /* 调整星号的大小 */
    color: red; /* 保持红色 */
    line-height: 1; /* 确保行高不会影响对齐 */
    margin-left: 4px; /* 可选：为星号添加左边距以增加间隔 */
    transform: translateY(2px); /* 向下移动星号 */
    display: inline-block; /* 确保 transform 生效 */
  }
}

/deep/.mtd-input.hover, .mtd-input:hover, .mtd-textarea.hover, .mtd-textarea:hover  {
  border-color:#5829fc;
}

.custom-textarea {
  resize: none; /* 禁用拖拽调整大小 */
}
</style>
