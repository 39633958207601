import {
  postInstanceCountTopRatingRanking,
  postInstanceCreateAndCheck,
  postInstanceGetCheckResultSummary,
  postInstanceMyRatingRanking,
  postInstanceTopRatingRanking,
} from '@/api/modules/instance';
import * as instanceEval from '@/api/modules/instanceEval';
import { Api, Spectra, SpectraModule } from '@/decorators';

@Spectra({ namespaced: true, name: 'Instance' })
export default class extends SpectraModule {
  @Api({ defaultState: { instance: { status: '' } } })
  pollingCheckResult = postInstanceGetCheckResultSummary;
  @Api()
  checkInstance = postInstanceCreateAndCheck;
  @Api()
  rankingList = postInstanceTopRatingRanking;
  @Api()
  myRanking = postInstanceMyRatingRanking;
  @Api()
  ratingRanking = instanceEval.postInstanceEvalRatingRanking;
  @Api()
  rankingListCount = postInstanceCountTopRatingRanking;
}
