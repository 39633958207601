import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1463501, catid=251501, projectId=41427, created=2024-08-27 14:38:29, last-modified=2024-11-27 15:42:08
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instance/myRatingRanking)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463501)
 */
export function postInstanceMyRatingRanking(
  params: model.instance.IPostInstanceMyRatingRankingParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instance.IPostInstanceMyRatingRankingResponse>('/webApi/instance/myRatingRanking', {
    data: pick(params, ['themeId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1463499, catid=251501, projectId=41427, created=2024-08-27 14:32:36, last-modified=2024-11-07 11:07:59
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instance/topRatingRanking)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463499)
 */
export function postInstanceTopRatingRanking(
  params: model.instance.IPostInstanceTopRatingRankingParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instance.IPostInstanceTopRatingRankingResponse>('/webApi/instance/topRatingRanking', {
    data: pick(params, ['themeId', 'topNum']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1488435, catid=251501, projectId=41427, created=2024-11-07 11:06:25, last-modified=2024-11-18 17:20:31
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instance/count/topRatingRanking)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1488435)
 */
export function postInstanceCountTopRatingRanking(
  params: model.instance.IPostInstanceCountTopRatingRankingParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instance.IPostInstanceCountTopRatingRankingResponse>('/webApi/instance/count/topRatingRanking', {
    data: pick(params, ['themeId', 'topNum']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1471564, catid=250712, projectId=41427, created=2024-09-13 19:29:56, last-modified=2024-09-13 19:34:10
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instance/createAndCheck_1726226996281)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1471564)
 */
export function postInstanceCreateAndCheck1726226996281(
  params: model.instance.IPostInstanceCreateAndCheck1726226996281Parameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instance.IPostInstanceCreateAndCheck1726226996281Response>('/webApi/instance/createAndCheck_1726226996281', {
    data: pick(params, ['id']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1457381, catid=250712, projectId=41427, created=2024-08-22 10:43:17, last-modified=2024-11-14 16:42:38
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instance/createAndCheck)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457381)
 */
export function postInstanceCreateAndCheck(params: model.instance.IPostInstanceCreateAndCheckParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.instance.IPostInstanceCreateAndCheckResponse>('/webApi/instance/createAndCheck', {
    data: pick(params, ['originInput', 'correctAnswer', 'themeId', 'explanation']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1460061, catid=250712, projectId=41427, created=2024-08-23 10:54:50, last-modified=2024-10-15 17:04:46
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/instance/getCheckResultSummary)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1460061)
 */
export function postInstanceGetCheckResultSummary(
  params: model.instance.IPostInstanceGetCheckResultSummaryParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.instance.IPostInstanceGetCheckResultSummaryResponse>('/webApi/instance/getCheckResultSummary', {
    data: pick(params, ['id']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
