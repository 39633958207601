import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1476182, catid=253533, projectId=41427, created=2024-09-24 02:43:04, last-modified=2024-10-14 14:27:40
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/appeal)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1476182)
 */
export function postDifficultAppeal(params: model.difficult.IPostDifficultAppealParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.difficult.IPostDifficultAppealResponse>('/webApi/difficult/appeal', {
    data: pick(params, ['instanceEvalId', 'type', 'extraInfo']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1490076, catid=253533, projectId=41427, created=2024-11-14 10:30:24, last-modified=2024-11-18 14:02:49
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/appeal/replenish)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1490076)
 */
export function postDifficultAppealReplenish(
  params: model.difficult.IPostDifficultAppealReplenishParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.difficult.IPostDifficultAppealReplenishResponse>('/webApi/difficult/appeal/replenish', {
    data: pick(params, ['appealId', 'extraInfo', 'viewpoint']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1479456, catid=253533, projectId=41427, created=2024-09-27 18:19:37, last-modified=2024-09-27 19:01:13
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/upload)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1479456)
 */
export function postDifficultUpload(params: model.difficult.IPostDifficultUploadParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.difficult.IPostDifficultUploadResponse>('/webApi/difficult/upload', {
    data: pick(params, ['appealId', 'title']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1479457, catid=253533, projectId=41427, created=2024-09-27 18:24:27, last-modified=2024-10-12 12:09:28
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/page)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1479457)
 */
export function postDifficultPage(params: model.difficult.IPostDifficultPageParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.difficult.IPostDifficultPageResponse>('/webApi/difficult/page', {
    data: pick(params, ['pageNum', 'pageSize']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1479461, catid=253533, projectId=41427, created=2024-09-27 18:42:24, last-modified=2024-10-12 18:02:56
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/detail)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1479461)
 */
export function postDifficultDetail(params: model.difficult.IPostDifficultDetailParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.difficult.IPostDifficultDetailResponse>('/webApi/difficult/detail', {
    data: pick(params, ['difficultId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1479466, catid=253533, projectId=41427, created=2024-09-27 18:52:34, last-modified=2024-10-11 17:35:47
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/vote)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1479466)
 */
export function postDifficultVote(params: model.difficult.IPostDifficultVoteParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.difficult.IPostDifficultVoteResponse>('/webApi/difficult/vote', {
    data: pick(params, ['difficultId', 'result', 'reason']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1480438, catid=253533, projectId=41427, created=2024-10-11 10:10:38, last-modified=2024-10-14 16:31:26
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/myVote)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1480438)
 */
export function postDifficultMyVote(params: model.difficult.IPostDifficultMyVoteParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.difficult.IPostDifficultMyVoteResponse>('/webApi/difficult/myVote', {
    data: pick(params, ['pageNum', 'pageSize']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1480472, catid=253533, projectId=41427, created=2024-10-11 11:04:27, last-modified=2024-10-11 11:20:32
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/myVote/reminderRemove)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1480472)
 */
export function postDifficultMyVoteReminderRemove(
  params: model.difficult.IPostDifficultMyVoteReminderRemoveParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.difficult.IPostDifficultMyVoteReminderRemoveResponse>('/webApi/difficult/myVote/reminderRemove', {
    data: pick(params, ['difficultVoteIds']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1480667, catid=253533, projectId=41427, created=2024-10-12 12:33:32, last-modified=2024-10-12 12:54:54
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/difficult/nextDifficult)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1480667)
 */
export function postDifficultNextDifficult(params: model.difficult.IPostDifficultNextDifficultParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.difficult.IPostDifficultNextDifficultResponse>('/webApi/difficult/nextDifficult', {
    data: pick(params, ['difficultId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
