export const CatchError = function (_target: any, _propertyKey: string, descriptor: TypedPropertyDescriptor<any>) {
  const originalMethod = descriptor.value;
  descriptor.value = async function (...args: any[]) {
    try {
      const result = originalMethod.apply(this, args);
      if (Promise.resolve(result) === result) {
        await result;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return descriptor;
};
