import Vue from 'vue';
import MessageComponent from '@/components/message/Message.vue';
// eslint-disable-next-line no-duplicate-imports
import { PluginObject } from 'vue';

const MessageConstructor = Vue.extend(MessageComponent);

function showMessage(options: any) {
  const instance = new MessageConstructor({
    data: typeof options === 'string' ? { message: options } : options,
  }).$mount();

  document.body.appendChild(instance.$el);
  instance.show(options);
}

const agiEvalPlugin: PluginObject<void> = {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  install(Vue) {
    Vue.prototype.$agitd = {
      message: {
        success(message: string | { message: string }) {
          showMessage({ type: 'success', message });
        },
        error(message: string | { message: string }) {
          showMessage({ type: 'error', message });
        },
      },
    };
  },
};

export default agiEvalPlugin;
