var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('mtd-modal',{class:{ 'agi-model-mobile': !_vm.isPC(), 'agi-model-mobile-setName': _vm.setName },attrs:{"keyboard":_vm.keyboard},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('div',{staticClass:"initial-model",style:({ height: _vm.styleModel && _vm.styleModel.modelHeight, minHeight: _vm.styleModel && _vm.styleModel.modelHeight })},[_c('div',{class:_vm.isCurRoute,staticStyle:{"display":"flex","justify-content":"space-between"}},[_vm._v(" AGI-Eval "),(_vm.close)?_c('i',{staticClass:"mtdicon mtdicon-close close",on:{"click":_vm.close}}):_vm._e()]),_c('div',{staticClass:"info-content"},[(_vm.modalContent.bigHeadline)?_c('div',{staticClass:"bigHeadline",style:(_vm.modalContent.bigHeadlineStyle),domProps:{"innerHTML":_vm._s(_vm.modalContent.bigHeadline)}}):_vm._e(),(_vm.twoStep)?_c('div',{staticClass:"progress-bar-box"},[_c('progress-bar',{staticClass:"progress-bar",attrs:{"progress":100,"text":false,"animate":true}}),_c('div',{staticClass:"progress-text"},[_vm._v("100%")])],1):_vm._e(),(_vm.modalContent.smallHeadline)?_c('div',{staticClass:"smallHeadline",style:(_vm.modalContent.smallHeadlineStyle),domProps:{"innerHTML":_vm._s(_vm.modalContent.smallHeadline)}}):_vm._e(),(_vm.modalContent.markednessHeadline)?_c('div',{staticClass:"markednessHeadline",style:(_vm.modalContent.markednessHeadlineStyle),domProps:{"innerHTML":_vm._s(_vm.modalContent.markednessHeadline)}}):_vm._e(),(_vm.modalContent.imgUrl)?_c('div',{staticClass:"imgUrl",style:({
          ..._vm.modalContent.imgUrlStyle,
          backgroundImage: `url(${_vm.modalContent.imgUrl})`,
        })}):_vm._e(),(_vm.twoStep)?_c('div',{staticClass:"pagingDevice"},[_c('span',{staticClass:"active"}),_c('span')]):_vm._e(),(_vm.modalContent.bigImgUrl)?_c('div',{staticClass:"bigImgUrl",style:({
          ..._vm.modalContent.bigImgUrlStyle,
          backgroundImage: `url(${_vm.modalContent.bigImgUrl})`,
        })}):_vm._e(),_vm._l((_vm.modalContent.bigHeadlineList),function(bigHeadline){return _c('div',{key:bigHeadline,staticClass:"bigHeadline",style:(_vm.modalContent.bigHeadlineStyle),domProps:{"innerHTML":_vm._s(bigHeadline)}})}),(_vm.modalContent.widthImgUrl)?_c('div',{style:(_vm.modalContent.widthImgUrlStyle)},[_c('img',{staticClass:"widthImgUrl",attrs:{"src":_vm.modalContent.widthImgUrl,"alt":""}})]):_vm._e(),_vm._l((_vm.modalContent.promptTextList),function(promptText){return _c('div',{key:promptText,staticClass:"promptText",style:(_vm.modalContent.promptTextStyle),domProps:{"innerHTML":_vm._s(promptText)}})}),(_vm.modalContent.lightText)?_c('div',{staticClass:"lightText",style:(_vm.modalContent.lightTextStyle)},[_c('span',{staticClass:"lightTextText"},[_vm._v(_vm._s(_vm.modalContent.lightText.text))]),_c('mtd-popover',{attrs:{"trigger":"hover","placement":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.modalContent.lightText.hint)}})]},proxy:true}],null,false,2774042922)},[_c('span',{staticStyle:{"cursor":"pointer"},domProps:{"innerHTML":_vm._s(_vm.modalContent.lightText.icon)}})])],1):_vm._e(),(_vm.modalContent.bigHeadlineList?.length && _vm.modalContent.bigHeadlineList[0] === '去解锁新的模型吧！')?_c('div',{staticClass:"pagingDevice pagingDeviceBtm"},[_c('span'),_c('span',{staticClass:"active"})]):_vm._e(),_vm._t("btn"),_vm._t("tail")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }