import Layout from '@/views/Layout.vue';
const keepAlive = false;
/*
 * 如需使用动态import的分包特性，请注意优化分包规则和加载优化，避免打包出过多零散文件包
 * ps: 内网项目体积较小的情况下不太建议使用分包，分包过多反而容易打满浏览器并发连接数限制
 * webpackChunkName: 分包名,相同名称的包会被打包在一起,建议按分模块分包
 * webpackPrefetch: 页面加载完成后预取
 * webpackPreload: 页面加载时预加载
 */
// const List = () => import(/* webpackChunkName: 'List' */ /* webpackPrefetch :true */ /* webpackPreload :true */ '@/views/application/List.vue');
export default {
  path: '/mvp',
  component: Layout,
  meta: {
    title: '首页',
  },
  children: [
    {
      path: '/mvp/home',
      name: 'mvp-home',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/Home.vue'),
      meta: {
        icon: 'mtdicon mtdicon-home-o',
        title: '评测社区首页',
        keepAlive,
        cid: 'c_techportal_c2czfgla',
      },
    },
    {
      path: '/mvp/topRanking',
      name: 'mvp-top-Ranking',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/ranking/Top10Ranking.vue'),
      meta: {
        icon: 'mtdicon mtdicon-home-o',
        title: '榜单',
        keepAlive,
        cid: 'c_techportal_aviqoj5d',
      },
    },
    {
      path: '/mvp/listSummaryIndex',
      name: 'mvp-list-summary-index',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/ranking/ListSummaryIndex.vue'),
      meta: {
        icon: 'mtdicon mtdicon-home-o',
        title: '总榜',
        keepAlive,
      },
    },
    {
      path: '/mvp/personalCenter',
      name: 'mvp-personal-center',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/personalCenter/index.vue'),
      meta: {
        icon: 'mtdicon mtdicon-home-o',
        title: '个人中心',
        keepAlive,
      },
    },
    {
      path: '/mvp/login',
      name: 'mvp-login',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/personalCenter/Login.vue'),
      meta: {
        icon: 'mtdicon mtdicon-home-o',
        title: '登录',
        keepAlive,
        cid: 'c_techportal_7nm2ueww',
      },
    },
    {
      path: '/mvp/listSummaryIndex/modelDiff',
      name: 'mvp-model-diff',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/modelDiff/index.vue'),
      meta: {
        icon: 'mtdicon mtdicon-home-o',
        title: '模型对比',
        keepAlive,
        cid: 'c_techportal_7nm2ueww',
      },
    },
  ],
};
