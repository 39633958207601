import Layout from '@/views/arena/Layout.vue';
const keepAlive = false;

export default {
  path: '/llmArena/trouble',
  component: Layout,
  meta: {
    title: '疑难专区',
  },
  children: [
    {
      path: 'overview',
      name: 'trouble-overview',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/arena/trouble/overview/List.vue'),
      meta: {
        title: '题目概览',
        keepAlive,
      },
    },
    {
      path: 'overview/detail',
      name: 'trouble-detail',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/arena/trouble/overview/Detail.vue'),
      meta: {
        title: '题目投票',
        keepAlive,
      },
    },
  ],
};
