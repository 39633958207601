import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1490826, catid=255131, projectId=41427, created=2024-11-15 11:22:15, last-modified=2024-11-15 11:24:51
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/community/feedback/save)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1490826)
 */
export function postCommunityFeedbackSave(params: model.community.IPostCommunityFeedbackSaveParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.community.IPostCommunityFeedbackSaveResponse>('/webApi/community/feedback/save', {
    data: pick(params, ['type', 'content', 'contactInfo']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
