import { FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1480379, catid=253703, projectId=41427, created=2024-10-10 19:03:36, last-modified=2024-11-04 17:04:53
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/common/constant)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1480379)
 */
export function postCommonConstant(options?: FlowHttpRequestOptions) {
  return http.post<model.common.IPostCommonConstantResponse>('/webApi/common/constant', {
    ...mixHeadersOptions(options, { 'Content-Type': 'application/x-www-form-urlencoded' }),
  });
}

/**
 * @desc id=1482391, catid=253703, projectId=41427, created=2024-10-21 16:39:33, last-modified=2024-10-21 17:12:49
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/common/image/url)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1482391)
 */
export function postCommonImageUrl(options?: FlowHttpRequestOptions) {
  return http.post<model.common.IPostCommonImageUrlResponse>('/webApi/common/image/url', {
    ...mixHeadersOptions(options, { 'Content-Type': 'application/x-www-form-urlencoded' }),
  });
}
