<template>
  <div id="agi-app" v-track="$route.meta?.cid" :class="{ 'mobile-layout': !isPC() }">
    <div class="layout">
      <img
        v-if="isPC()"
        :style="`filter: blur(${route === 'home' ? '3px' : '10px'})`"
        class="background-img"
        src="https://p0.meituan.net/llmarena/2b88cdc20cab12be9a0897e0abefedb61458025.png"
      />
      <img
        v-show="!isPC() && (route?.includes('home') || route?.includes('mobileHome'))"
        class="background-img"
        src="https://p0.meituan.net/llmarena/9f5c0d29540ad53d8c5aa0599eba85f32483893.png"
      />
      <img
        v-show="!isPC() && !route?.includes('home') && !route?.includes('mobileHome')"
        class="background-img"
        src="https://p0.meituan.net/llmarena/60d31aa0627cdd77ef4d7b6c69c1e45f2354810.png"
      />
      <div class="main"><router-view /></div>
    </div>
    <top-nav v-if="!route?.includes('login') && isPC()" />
    <TopNavModel v-if="!route?.includes('login') && !isPC()"/>
    <CommunityBottom v-if="isCurRoute('community-llmArena-home') && isPC()" class="communityBottom-arena"/>
  </div>
</template>

<script lang="ts">
import AgiModal from '@/components/AgiModal.vue';
import TopNav from '@/components/TopNav.vue';
import TopNavModel from '@/components/mobile/TopNav.vue';
import { Component, Vue } from '@/decorators';
// import $router from '@/router/index';
import CommunityBottom from '@/components/CommunityBottom.vue';
import { isPC } from '@/utils/import-all';

@Component({
  components: {
    TopNav,
    TopNavModel,
    AgiModal,
    CommunityBottom,
  },
})
export default class Layout extends Vue {
  get route() {
    return this.$route.name;
  }

  isPC = isPC;
  isUnPCCon = {
    bigHeadline: '<div>手机端适配中</div><div>更多体验移步pc端</div>',
    bigImgUrl: 'https://p0.meituan.net/llmarena/86b6ac12ea125f84163504ed7ceaf36c19129.png',
  };

  created() {
    if (!isPC()) {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=device-width, initial-scale=1, user-scalable=no';
      document.head.appendChild(meta);
    }
  }

  convertStyle() {
    document.body.style.setProperty('height', `${window.innerHeight}px`);
  }

  mounted() {
    this.convertStyle();
    window.addEventListener('resize', this.convertStyle);
    window.addEventListener('DOMContentLoaded', this.convertStyle);
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.convertStyle);
    window.removeEventListener('DOMContentLoaded', this.convertStyle);
  }

  isCurRoute(target) {
    return this.$route.name === target;
  }
}
</script>

<style>
#agi-app {
  width: 100%;
  height:100%;
  overflow: hidden;
  min-width: 1280px;
}
.unPc .initial-model {
  top: 100px;
  transform: translate(-50%, 0);
}
</style>

<style lang="scss" scoped>
.btn {
  width: 130px;
  border-radius: 1000px;
  height: 46px;
  line-height: 46px;
  transform: translateX(5px);
  font-family: meituan type;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  margin-right: 10px;
  &.ccc {
    background: #757575;
    color: #fff;
  }
}
.layout {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-color: rgb(88, 189, 251);
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  .main {
    width: 100%;
    min-width: 1280px;
    flex: 1 1 auto;
  }
  .background-img {
    position: absolute;
    top: 0;
    width: 1500px;
    height: 850px;
    top: 150px;
    left: 30%;
  }
}

.mobile-layout {
  width: 100vw !important;
  height: 100% !important;
  min-width: 0 !important;
  .layout {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    background: 0;
    box-shadow: none;
    position: relative;
    background: rgba($color: #000000, $alpha: 0);
    padding: 0;
    .main {
      min-width: 0 !important;
      width: 100vw !important;
      height: 100% !important;
    }
    .background-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100% !important;
      z-index: -1;
      filter: none !important;
    }
  }
}

.communityBottom-arena {
  position: absolute;
  bottom: 0;
}
</style>
