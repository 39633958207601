import { postPersonUserInfo, postPersonModelList, postPersonSwitch, postPersonUnlock } from '@/api/modules/person';
import { Spectra, SpectraModule, Api } from '@/decorators';

@Spectra({ namespaced: true, name: 'Person' })
export default class extends SpectraModule {
  @Api() getPersonUserInfo = postPersonUserInfo;
  @Api() postPersonModelList = postPersonModelList;
  @Api() postPersonSwitch = postPersonSwitch;
  @Api() postPersonUnlock = postPersonUnlock;
}
