import Vue from 'vue';
const tracker = window.LXAnalytics('getTracker');

const user = JSON.parse(localStorage.getItem('user') || '{}');
tracker('set', 'uid', Number(user.id) || -1);
Vue.mixin({
  computed: {
    LXTracker() {
      return tracker;
    },
  },
});

declare module 'vue/types/vue' {
  interface Vue {
    LXTracker: any;
  }
}

export default tracker;
