import { quiz } from '@/api/index';
import { postInstanceEvalChat, postInstanceEvalChatLogFeedback } from '@/api/modules/instanceEval';
import { Api, ApiAction, Spectra, SpectraModule } from '@/decorators';

@Spectra({ namespaced: true, name: 'ModelChat' })
export default class extends SpectraModule {
  @Api({ defaultState: { chatLogList: [] } })
  chatLogList = postInstanceEvalChat;
  @ApiAction()
  chatFeedBack = postInstanceEvalChatLogFeedback;
  @Api()
  quizEnterQuiz = quiz.postQuizEnterQuiz;
  @Api()
  quizChatLogFeedback = quiz.postQuizChatLogFeedback;
}
