import * as difficult from '@/api/modules/difficult';
import { postDifficultAppeal, postDifficultAppealReplenish } from '@/api/modules/difficult';
import { Api, Spectra, SpectraModule } from '@/decorators';

@Spectra({ namespaced: true, name: 'Difficult' })
export default class extends SpectraModule {
  @Api()
  postDifficultPage = difficult.postDifficultPage;
  @Api()
  difficultDetail = difficult.postDifficultDetail;
  @Api()
  getNextId = difficult.postDifficultNextDifficult;
  @Api()
  myVote = difficult.postDifficultMyVote;
  @Api()
  removeNew = difficult.postDifficultMyVoteReminderRemove;
  @Api()
  difficultVote = difficult.postDifficultVote;
  @Api() difficultAppeal = postDifficultAppeal;
  @Api() difficultAppealReplenish = postDifficultAppealReplenish;
}
