import 'vue-class-component/hooks';
import Component from 'vue-class-component';

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

export * from '@snfe/flow-utils/dist/decorators';
export * from 'vue-property-decorator';
export * from 'vuex-class';
export * from '@snfe/spectra';
export * from './catchError';
