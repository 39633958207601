import { FlowHttpRequestOptions, mixHeadersOptions, pick } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1457002, catid=250618, projectId=41427, created=2024-08-21 11:33:30, last-modified=2024-08-21 14:24:58
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/generateCaptcha)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457002)
 */
export function postUserGenerateCaptcha(params: model.user.IPostUserGenerateCaptchaParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserGenerateCaptchaResponse>('/webApi/user/generateCaptcha', {
    data: pick(params, ['eventUniqueId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1480288, catid=250618, projectId=41427, created=2024-10-10 15:32:45, last-modified=2024-10-11 11:17:31
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/updatePassword)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1480288)
 */
export function postUserUpdatePassword(params: model.user.IPostUserUpdatePasswordParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserUpdatePasswordResponse>('/webApi/user/updatePassword', {
    data: pick(params, ['oldPassword', 'newPassword']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1485247, catid=250618, projectId=41427, created=2024-11-04 14:40:26, last-modified=2024-11-04 15:27:16
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/loginBySmsCode)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1485247)
 */
export function postUserLoginBySmsCode(params: model.user.IPostUserLoginBySmsCodeParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserLoginBySmsCodeResponse>('/webApi/user/loginBySmsCode', {
    data: pick(params, ['eventUniqueId', 'captchaVerifyCode', 'smsVerifyCode', 'mobileNumber']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1456987, catid=250618, projectId=41427, created=2024-08-21 11:24:23, last-modified=2024-08-26 11:19:29
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/sendSmsVerifyCode)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1456987)
 */
export function postUserSendSmsVerifyCode(params: model.user.IPostUserSendSmsVerifyCodeParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserSendSmsVerifyCodeResponse>('/webApi/user/sendSmsVerifyCode', {
    data: pick(params, ['eventUniqueId', 'captchaVerifyCode', 'mobileNumber']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1457058, catid=250618, projectId=41427, created=2024-08-21 14:22:12, last-modified=2024-10-09 19:14:43
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/register)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457058)
 */
export function postUserRegister(params: model.user.IPostUserRegisterParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserRegisterResponse>('/webApi/user/register', {
    data: pick(params, ['smsVerifyCode', 'mobileNumber', 'password', 'userType']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1457114, catid=250618, projectId=41427, created=2024-08-21 14:59:58, last-modified=2024-10-10 10:29:00
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/forgetPassword)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457114)
 */
export function postUserForgetPassword(params: model.user.IPostUserForgetPasswordParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserForgetPasswordResponse>('/webApi/user/forgetPassword', {
    data: pick(params, ['smsVerifyCode', 'newPassword', 'mobileNumber']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1457097, catid=250618, projectId=41427, created=2024-08-21 14:40:46, last-modified=2024-08-21 14:55:15
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/loginByPassword)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457097)
 */
export function postUserLoginByPassword(params: model.user.IPostUserLoginByPasswordParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserLoginByPasswordResponse>('/webApi/user/loginByPassword', {
    data: pick(params, ['eventUniqueId', 'captchaVerifyCode', 'mobileNumber', 'password']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1457108, catid=250618, projectId=41427, created=2024-08-21 14:55:52, last-modified=2024-08-21 14:56:16
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/logout)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457108)
 */
export function postUserLogout(params: model.user.IPostUserLogoutParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserLogoutResponse>('/webApi/user/logout', {
    data: params,
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1457138, catid=250618, projectId=41427, created=2024-08-21 15:37:59, last-modified=2024-10-11 14:31:27
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/updateName)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457138)
 */
export function postUserUpdateName(params: model.user.IPostUserUpdateNameParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserUpdateNameResponse>('/webApi/user/updateName', {
    data: pick(params, ['newName', 'organization', 'sex', 'age', 'education', 'major', 'job', 'userType']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1457170, catid=250618, projectId=41427, created=2024-08-21 15:43:30, last-modified=2024-10-10 15:19:51
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/user/me)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457170)
 */
export function postUserMe(params: model.user.IPostUserMeParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.user.IPostUserMeResponse>('/webApi/user/me', {
    data: params,
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
