import Layout from '@/views/Layout.vue';
const keepAlive = false;
/*
 * 如需使用动态import的分包特性，请注意优化分包规则和加载优化，避免打包出过多零散文件包
 * ps: 内网项目体积较小的情况下不太建议使用分包，分包过多反而容易打满浏览器并发连接数限制
 * webpackChunkName: 分包名,相同名称的包会被打包在一起,建议按分模块分包
 * webpackPrefetch: 页面加载完成后预取
 * webpackPreload: 页面加载时预加载
 */
// const List = () => import(/* webpackChunkName: 'List' */ /* webpackPrefetch :true */ /* webpackPreload :true */ '@/views/application/List.vue');
export default {
  path: '/contributor',
  component: Layout,
  meta: {
    title: '贡献者',
  },
  redirect: '/contributor/list',
  children: [
    {
      path: '/contributor/list',
      name: 'contributor-list',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/contributor/list.vue'),
      meta: {
        title: '贡献者列表',
        keepAlive,
      },
    },
    {
      path: '/contributor/:name',
      name: 'contributor-detail',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/contributor/detail.vue'),
      meta: {
        title: '贡献者详情',
        keepAlive,
        cid: 'c_techportal_cm8u8i8f',
      },
    },
  ],
};
