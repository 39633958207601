import { FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1457375, catid=250706, projectId=41427, created=2024-08-22 10:39:05, last-modified=2024-08-29 16:43:45
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/system/getGlobalConfig)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457375)
 */
export function postSystemGetGlobalConfig(params: model.system.IPostSystemGetGlobalConfigParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.system.IPostSystemGetGlobalConfigResponse>('/webApi/system/getGlobalConfig', {
    data: params,
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
