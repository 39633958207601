import { ssoURL } from '@/utils/sso';
import { factory } from '@snfe/flow-http';
import Vue from 'vue';

export const baseURL = '';
let status = 'login';
const getLoginUrl = () => {
  const originUrl = new URL(location.href);
  originUrl.searchParams.delete('code'); // 删除 code 参数
  const cleanOriginUrl = originUrl.toString();
  return `${ssoURL}/login?status=${status}&originUrl=${encodeURIComponent(cleanOriginUrl)}&userType=EXTERNAL`;
};

const validateResponse = (data) => data?.rescode === 0;

const isUnauthorized = (data) => {
  const isUnauthorizedResponse = data.status === 401 || data.data?.rescode === 4010;

  if (isUnauthorizedResponse) {
    status = data.data?.rescode === 4010 ? 'register' : 'login';

    if (process.env.CONFIG_ENV === 'staging') {
      location.href = getLoginUrl();
      return false;
    }
  }

  return isUnauthorizedResponse;
};

const swimLane = window.location.host.split('-sl-');
const headers = { 'X-Requested-With': 'XMLHttpRequest' };
switch (process.env.CONFIG_ENV) {
  case 'staging':
    headers['x-gray-set'] = 'preonline';
    break;
  case 'test':
    headers['x-gray-set'] = swimLane.length > 1 ? swimLane[0] : '';
    break;
  default:
    break;
}

const http = factory({
  headers,
  baseURL: process.env.NODE_ENV === 'production' && process.env.CONFIG_ENV === 'staging' ? 'https://agi-eval.cn' : '',
  notifyMessage: (data) => Vue.prototype.$agitd.message.error(data.message),
  loginUrl: getLoginUrl(),
  validateResponse,
  isUnauthorized,
  rethrowError: true,
  extractData: false,
});

export default http;
