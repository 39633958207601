import { OverlayScrollbars } from 'overlayscrollbars';
import 'overlayscrollbars/overlayscrollbars.css';

export default {
  inserted(el, binding) {
    setTimeout(() => OverlayScrollbars(el, {
      scrollbars: {
        visibility: 'auto',
        autoHide: 'scroll',
      },
      ...binding.value,
    }));
  },
};
