<template>
  <div class="community-bottom" :style="{ background: bagColor }">
    <div class="community-bottom-icon">
      <img src="https://p0.meituan.net/llmarena/312bf2d7ad4b59e48f94f046ab17560a32914.png" alt=""/>
      <div class="title">AGI-Eval</div>
    </div>
    <div>
      <span class="record">©2025 AGI-Eval All Rights Reserved</span>
      <span class="record ml-[10px] text" @click="goDataStudio('https://beian.miit.gov.cn')">
        @沪ICP备2024098885号-1
      </span>
    </div>
    <mtd-popover trigger="hover" title="" placement="top">
      <img src="https://p0.meituan.net/llmarena/23d64bc12e383cb3d40f10535a394a372780.png" alt=""/>
      <div slot="content" class="wechat">
        <!-- <img src="https://p0.meituan.net/llmarena/120206cd7b262515e1aaa31a83d7a352150507.jpg" alt=""/> -->
        <img src="../styles/img/helper.png" alt="" />
      </div>
    </mtd-popover>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
  @Component
export default class CommunityBottom extends Vue {
    @Prop() bagColor!: string;

    routerButtonMeta = [
      {
        title: '评测榜单',
        target: 'mvp-top-Ranking',
      },
      {
        title: '人机比赛',
        target: 'community-llmArena-home',
      },
      {
        title: '评测集社区',
        target: 'evaluation-home',
      },
    ];

    get imageSrc() {
      return this.isCurRoute('mvp-home')
        ? 'https://p0.meituan.net/llmarena/5fc91621ad2229330963418007fb7ff134140.png'
        : 'https://p0.meituan.net/llmarena/312bf2d7ad4b59e48f94f046ab17560a32914.png';
    }

    get dataImageSrc() {
      return this.isCurRoute('mvp-home')
        ? 'https://p0.meituan.net/llmarena/a794a1c704f7d79a38d337483481e1ac314.png'
        : 'https://p0.meituan.net/llmarena/91dacc5251fc478ebf4a9648b0e48a56298.png';
    }

    goDataStudio(url) {
      window.open(url);
    }

    jump(target, name) {
      if (name === '人机比赛') {
        window.open(
          this.$router.resolve({
            name: target,
          }).href,
          '_blank'
        );
      } else {
        this.$route.name !== target && this.$router.push({ name: target });
      }
    }
    isCurRoute(target) {
      return this.$route.name === target;
    }
}
</script>
  <style lang="scss" scoped>
  .community-bottom {
   // border-top: 1px solid #ece4ff;
    //background-color: #fff;
    display: flex;
    left: 0;
    bottom: 0;
    height: 60px;
    width: 100%;
    padding: 0 60px 0 40px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    min-width: 1280px;
    .community-bottom-icon {
      display: flex;
      align-items: center;
      .title {
        font-size: 18px;
        font-weight: 500;
        margin: 0 20px;
      }

    }

    .record {
      color: #A2A0A0;
      font-size:14px;
    }

    img {
      width: 30px;
      height:30px
    }
  }
  .wechat {
    background-color: #fff;
    padding: 10px;
    width:200px;
    img {
      width: 200px;
      height:240px;
    }
  }
  .text:hover {
    color: #4c26f2;
    cursor: pointer
  }

  </style>
