import { Spectra, SpectraPrism } from '@snfe/spectra';
import axios, { CancelToken, CancelTokenSource } from 'axios';

export interface Request {
  isPureApi: boolean;
  apiPromise?: Promise<any>;
  apiOptions: any;
  api: (...params: any[]) => Promise<any>;
  apiResult?: any;
  apiArgs: any[];
  cancelTokenSource: CancelTokenSource;
}

@Spectra
export class RequestAspect extends SpectraPrism {
  beforeAction(context, moduleActionContext) {
    const { apiArgs, actionName, api, apiOptions, isPureApi } = moduleActionContext;
    const cancelTokenSource = axios.CancelToken.source();
    const options: { cancelToken: CancelToken; baseURL?: string } = { cancelToken: cancelTokenSource.token };
    if (apiOptions.mock) {
      options.baseURL = '/mock';
    }

    if (!apiArgs[1]) {
      apiArgs.push(options);
    } else {
      Object.assign(apiArgs[1], options);
    }

    moduleActionContext.cancelTokenSource = cancelTokenSource;
    const request: Request = { cancelTokenSource, apiArgs, api, apiOptions, isPureApi };
    context.commit('setRequest', { actionName, loading: true, request }, { root: true });
  }

  pendingAction(context, moduleActionContext) {
    const { apiArgs, apiResult, actionName, apiPromise, api, apiOptions, isPureApi, cancelTokenSource } = moduleActionContext;
    const request: Request = { isPureApi, cancelTokenSource, api, apiArgs, apiOptions, apiPromise, apiResult };
    context.commit('setRequest', { actionName, loading: true, request }, { root: true });
  }

  afterAction(context, moduleActionContext) {
    const { actionName } = moduleActionContext;
    context.commit('setRequest', { actionName, loading: false, request: null }, { root: true });
  }
}
