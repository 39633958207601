import Vue from 'vue';
import './styles/tailwind.css';
import './styles/var.scss';

import App from './App.vue';
import router from './router';
import store from './store';
import MTD from '@ss/mtd-vue';
import '@ss/mtd-vue/lib/theme2/index.css';
import '@/styles/index.scss';
import agiEvalPlugin from '@/components/message/agiEvalPlugin';
import trackDirective from '@/directive/trackDirective';
import agiScroll from '@/directive/agiScroll';
Vue.use(MTD);
Vue.config.productionTip = false;
Vue.use(agiEvalPlugin);
Vue.directive('track', trackDirective);
Vue.directive('scroll', agiScroll);

const app_version = localStorage.getItem('app_version');
const currentVersion = (window as any).__current_version__;

if (Number(app_version) !== Number(currentVersion)) {
  localStorage.setItem('app_version', currentVersion);
  window.location.reload();
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
