import LXTracker from '@/utils/lxReport';
/**
 * 人机：
 * 首页 c_techportal_v89rn38y
 * 出题详情 c_techportal_vbqtl8r6
 * 做题详情 c_techportal_o6qw4fcd
 * 登录 c_techportal_a098mlc9
 *
 * 社区
 * 登录 c_techportal_7nm2ueww
 * 首页 c_techportal_c2czfgla
 * 评测榜单 c_techportal_aviqoj5d
 * 评测集社区 c_techportal_q2xymwsy
 * 贡献者 c_techportal_cm8u8i8f
 * 导航栏 c_techportal_66wjvrus
 */

const agiEvalJumpList = ['community-top-nav', 'community-llmArena-home', 'community-section-bottom'];
const machineJumpList = ['MACHINE-TOP'];
// 生产上报
const isProd = process.env.NODE_ENV === 'production';
// const isProd = true;

const handleBeforeUnload = () => {
  try {
    if (!isProd) return;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const valLab = { custom: { user_id: user.id, timestamp: Date.now() } };
    (window as any).pageCase && (window as any).pageCase('pageDisappear', valLab);

    localStorage.removeItem('agi-eval-jump');
    localStorage.removeItem('man-machine-jump');
  } catch (e) {
    console.log(e);
  }
};

export default {
  inserted(el, binding) {
    try {
      if (!isProd) return;
      let cid: any;
      let contributor_name: any;
      let sourcePage: any;
      if (typeof binding.value === 'string') {
        cid = binding.value;
      } else if (typeof binding.value === 'object') {
        cid = binding.value?.cid;
        contributor_name = binding.value?.contributorName;
        // sourcePage 外部来源
        sourcePage = binding.value?.sourcePage;
      }

      const agiEvalJump = localStorage.getItem('agi-eval-jump');
      const machineJump = localStorage.getItem('man-machine-jump');
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      // 内部来源
      let source = 'URL';
      // 社区到人机
      if (cid === 'c_techportal_v89rn38y') source = agiEvalJumpList.includes(agiEvalJump || 'agiEvalJump') ? 'AGI' : 'URL';
      // 人机到社区
      if (cid === 'c_techportal_c2czfgla') source = machineJumpList.includes(machineJump || 'machineJump') ? 'MACHINE' : 'URL';
      const pageCase = LXTracker(
        'pageView',
        {
          custom: {
            user_id: user.id,
            // 内部来源
            source,
            contributor_name,
            // sourcePage 外部来源
            sourcePage,
            timestamp: Date.now(),
          },
        },
        {},
        cid,
      );

      // 首页使用window做载体，用于tab页卸载上报
      if (cid === 'c_techportal_v89rn38y' || cid === 'c_techportal_c2czfgla') {
        (window as any).pageCase = pageCase;
        localStorage.removeItem('man-machine-jump');
      } else {
        el.pageCase = pageCase;
      }

      localStorage.removeItem('agi-eval-jump');
      window.addEventListener('beforeunload', handleBeforeUnload);
      el._onBeforeUnload = handleBeforeUnload;
    } catch (e) {
      console.log(e);
    }
  },

  unbind(el, binding) {
    try {
      if (!isProd) return;
      let cid: any;
      if (typeof binding.value === 'string') {
        cid = binding.value;
      } else if (typeof binding.value === 'object') {
        cid = binding.value?.cid;
      }

      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const valLab = { custom: { user_id: user.id, timestamp: Date.now() } };
      if (cid === 'c_techportal_v89rn38y' || cid === 'c_techportal_c2czfgla') {
        (window as any).pageCase && (window as any).pageCase('pageDisappear', valLab);
      } else {
        el.pageCase('pageDisappear', valLab);
      }

      window.removeEventListener('beforeunload', el._onBeforeUnload);
      delete el._onBeforeUnload;
    } catch (e) {
      console.log(e);
    }
  },
};
