import { user } from '@/api/index';
import { postCommonConstant, postCommonImageUrl } from '@/api/modules/common';
import { Api, Spectra, SpectraModule } from '@/decorators';

@Spectra({ namespaced: true, name: 'Common' })
export default class extends SpectraModule {
  @Api() commonConstant = postCommonConstant;
  @Api() imageList = postCommonImageUrl;
  @Api() userMes = user.postUserMe;
}
