import store from '@/store';
import { importAll, ImportAllResult } from '@/utils';
import { isPC } from '@/utils/import-all';
import { ssoURL } from '@/utils/sso';
import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { agi, mvp } from './base';

const routeModules: ImportAllResult[] = importAll(require.context('@/router/modules', false, /\.ts$/));
const routes = routeModules.reduce((finalRoutes: Array<any>, routerModule) => finalRoutes.concat(routerModule.module), []);

const base = process.env.NODE_ENV === 'production' ? '' : '/community/';

const $router = new VueRouter({
  mode: 'history',
  base,
  routes: [
    {
      path: '/',
      redirect: '/mvp/home',
    },
    ...routes,
  ],
});
Vue.use(VueRouter);

const handleSSO = async (to, next) => {
  try {
    const code = to.query?.code;

    if (!code) {
      return next();
    }

    const clientId = 'cqwm40qkh';

    const obj = {
      code,
      clientId,
    };

    // 发送请求获取 accessToken
    const response = await axios.post(`${ssoURL}/llmSso/code`, obj);
    const accessToken = response.data.data;

    document.cookie = 'access-token=; max-age=0;'; // 删除 access-token
    document.cookie = 'client-id=; max-age=0;'; // 删除 client-id

    document.cookie = `access-token=${accessToken}; max-age=${3 * 24 * 60 * 60}; path=/webApi;`;
    document.cookie = `client-id=${clientId}; max-age=${3 * 24 * 60 * 60}; path=/webApi;`;

    const { code: _, ...newQuery } = to.query;
    const queryString = new URLSearchParams(newQuery).toString();
    const newUrl = queryString ? `${to.path}?${queryString}` : to.path;
    store.dispatch('getUserInfo');
    return next({ path: newUrl });
  } catch (error) {
    console.error('获取 token 失败:', error);
    return next(error);
  }
};

$router.beforeEach(async (to: any, from, next) => {
  await handleSSO(to, next);
  let favicon = mvp;
  document.title = 'AGI-Eval评测社区';
  if (to.path.includes('/llmArena')) {
    favicon = agi;
    document.title = 'AGI-Eval人机比赛';
  }

  const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/png';
  link.rel = 'icon';
  link.href = favicon;
  document.head.appendChild(link);

  if (isPC() && to?.name?.includes('mobile')) {
    store.commit('intoMobileHomeChange', true);
    $router.push('/llmArena/home');
  } else if (!isPC() && to?.name?.includes('llmArena-home')) {
    $router.push('/llmArena/mobile/home');
  }

  next();
});

window.$router = $router;
export default $router;
