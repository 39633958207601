/*
 * 如需使用动态import的分包特性，请注意优化分包规则和加载优化，避免打包出过多零散文件包
 * ps: 内网项目体积较小的情况下不太建议使用分包，分包过多反而容易打满浏览器并发连接数限制
 * webpackChunkName: 分包名,相同名称的包会被打包在一起,建议按分模块分包
 * webpackPrefetch: 页面加载完成后预取
 * webpackPreload: 页面加载时预加载
 */
// const List = () => import(/* webpackChunkName: 'List' */ /* webpackPrefetch :true */ /* webpackPreload :true */ '@/views/application/List.vue');

export default {
  path: '/llmArena/agreement',
  name: 'llmArena-agreement',
  component: () => import(/* webpackChunkName: 'Basic' */ '@/views/arena/agreement/Agreement.vue'),
  meta: {
    title: '协议',
  },
};
