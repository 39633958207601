const fileNameRE = /^\.\/([^\s]+)\.ts$/; // ./xxx.ts
// 生产上报
const isProd = process.env.NODE_ENV === 'production';
// const isProd = true;

export interface ImportAllResult {
  moduleName: string;
  camelModuleName: string;
  module: any;
}
export function importAll(modulesContext: any): ImportAllResult[] {
  return modulesContext.keys().map((modulesPath: string) => {
    const moduleName = (modulesPath.match(fileNameRE) as RegExpMatchArray)[1];
    return {
      moduleName,
      camelModuleName: moduleName.replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : '')),
      module: modulesContext(modulesPath).default,
    };
  });
}

export function isPC() {
  const userAgentInfo = navigator.userAgent;
  const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
  const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

  let flag = true;
  if (width > 750) return true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0 || (width >= 372 && width <= 750)) {
      flag = false;
      break;
    }
  }

  return flag;
}

/**
 * el!: 元素
 *
 * bid!: bid
 *
 * type?: 上报类型
 *
 * value?: 其他上传参数
 */
export function track(el: any, bid: string, type = 'moduleClick', value = {}) {
  try {
    if (!isProd) return;
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const valLab = { custom: { user_id: user.id, timestamp: Date.now(), ...value } };

    if (el?.pageCase) {
      el.pageCase(
        type,
        bid,
        valLab,
      );
    } else if ((window as any).pageCase) {
      (window as any).pageCase(
        type,
        bid,
        valLab,
      );
    }
  } catch (e) {
    console.log(e);
  }
}
