import Layout from '@/views/arena/Layout.vue';
const keepAlive = false;

export default {
  path: '/llmArena/ranking',
  component: Layout,
  meta: {
    title: '榜单专区',
  },
  children: [
    {
      path: 'list',
      name: 'ranking-list',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/arena/ranking/List.vue'),
      props: { cfgInfo: { rankingType: 2, isMyRanking: 1 } },
      meta: {
        title: '榜单专区',
        keepAlive,
      },
    },
  ],
};
