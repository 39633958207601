<template>
  <div>
    <div v-if="text" class="text">{{ progress }}%</div>
    <div class="progress-bar-container">
      <div
        class="progress-bar"
        :class="{ 'animate': animate && hasStarted }"
        :style="barStyle"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class ProgressBar extends Vue {
  @Prop({ type: Boolean, default: true }) text!: boolean;
  @Prop({ type: Number, default: 0 }) progress!: number;
  @Prop({ type: Boolean, default: true }) animate!: boolean;

  private currentProgress = 0;
  private hasStarted = false;

  mounted() {
    this.currentProgress = 0;

    this.$nextTick(() => {
      setTimeout(() => {
        this.hasStarted = true;
        this.currentProgress = this.progress;
      }, 50);
    });
  }

  @Watch('progress')
  onProgressChanged(newVal: number) {
    if (this.animate) {
      setTimeout(() => {
        this.currentProgress = newVal;
      }, 50);
    } else {
      this.currentProgress = newVal;
    }
  }

  get barStyle() {
    return {
      width: `${this.currentProgress}%`,
      backgroundColor: '#FFD43B',
      border: '1px solid gray',
      borderRadius: '10px',
    };
  }
}
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  height: 17px;
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #747a7c;
  background: rgba(216, 216, 216, 0.2);
}

.progress-bar {
  height: 100%;
}

.progress-bar.animate {
  transition: width 1s ease-in-out;
}

.text {
  color: #FFD43B;
  font-family: Montserrat;
  font-size: 15px;
  font-style: italic;
  font-weight: 800;
  line-height: 18.29px;
  text-align: right;
  padding-right: 10px;
}
</style>
