import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1464412, catid=251576, projectId=41427, created=2024-08-28 10:58:38, last-modified=2024-08-28 11:18:07
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/external/chat/generate)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1464412)
 */
export function postExternalChatGenerate(params: model.external.IPostExternalChatGenerateParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.external.IPostExternalChatGenerateResponse>('/external/chat/generate', {
    data: pick(params, ['workerId', 'prompts', 'parameter']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1464395, catid=251576, projectId=41427, created=2024-08-28 10:44:06, last-modified=2024-08-28 11:26:16
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/external/chat/listWorker)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1464395)
 */
export function getExternalChatListWorker(params: model.external.IGetExternalChatListWorkerParameter, options?: FlowHttpRequestOptions) {
  return http.get<model.external.IGetExternalChatListWorkerResponse>('/external/chat/listWorker', {
    params: pick(params, ['modelId', 'group']),
    ...options,
  });
}
