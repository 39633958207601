import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1472059, catid=252643, projectId=41427, created=2024-09-18 16:35:45, last-modified=2024-09-18 16:52:38
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/person/reward)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1472059)
 */
export function postPersonReward(params: model.person.IPostPersonRewardParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.person.IPostPersonRewardResponse>('/webApi/person/reward', {
    data: pick(params, ['userId', 'themeId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1474861, catid=252643, projectId=41427, created=2024-09-20 14:16:26, last-modified=2024-10-08 10:32:00
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/person/modelList)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1474861)
 */
export function postPersonModelList(params: model.person.IPostPersonModelListParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.person.IPostPersonModelListResponse>('/webApi/person/modelList', {
    data: pick(params, ['themeId', 'onlyQueryCurrentAndLowerRank']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1474662, catid=252643, projectId=41427, created=2024-09-19 18:52:05, last-modified=2024-09-20 20:10:33
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/person/switch)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1474662)
 */
export function postPersonSwitch(params: model.person.IPostPersonSwitchParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.person.IPostPersonSwitchResponse>('/webApi/person/switch', {
    data: pick(params, ['themeId', 'modelId', 'instanceEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1474937, catid=252643, projectId=41427, created=2024-09-20 14:48:42, last-modified=2024-10-22 15:00:58
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/person/unlock)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1474937)
 */
export function postPersonUnlock(params: model.person.IPostPersonUnlockParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.person.IPostPersonUnlockResponse>('/webApi/person/unlock', {
    data: pick(params, ['themeId', 'rank', 'instanceEvalId']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1479381, catid=252643, projectId=41427, created=2024-09-27 15:39:56, last-modified=2024-11-07 15:17:55
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/person/userInfo)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1479381)
 */
export function postPersonUserInfo(params: model.person.IPostPersonUserInfoParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.person.IPostPersonUserInfoResponse>('/webApi/person/userInfo', {
    data: pick(params, ['themeId', 'config']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
