import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1463582, catid=250801, projectId=41427, created=2024-08-27 15:40:57, last-modified=2024-08-29 17:26:09
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/plugin/userModelMatch)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463582)
 */
export function postPluginUserModelMatch(params: model.plugin.IPostPluginUserModelMatchParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.plugin.IPostPluginUserModelMatchResponse>('/webApi/plugin/userModelMatch', {
    data: pick(params, ['modelList', 'user', 'config']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1463604, catid=250801, projectId=41427, created=2024-08-27 15:58:03, last-modified=2024-08-27 16:04:51
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/plugin/userModelInstanceMatch)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463604)
 */
export function postPluginUserModelInstanceMatch(
  params: model.plugin.IPostPluginUserModelInstanceMatchParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.plugin.IPostPluginUserModelInstanceMatchResponse>('/webApi/plugin/userModelInstanceMatch', {
    data: pick(params, ['instanceList', 'user', 'model', 'config']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1463629, catid=250801, projectId=41427, created=2024-08-27 16:41:37, last-modified=2024-08-29 17:48:07
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/plugin/calculateInstanceEvalRatingChange)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1463629)
 */
export function postPluginCalculateInstanceEvalRatingChange(
  params: model.plugin.IPostPluginCalculateInstanceEvalRatingChangeParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.plugin.IPostPluginCalculateInstanceEvalRatingChangeResponse>('/webApi/plugin/calculateInstanceEvalRatingChange', {
    data: pick(params, ['instance', 'user', 'model', 'instanceEval', 'config']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1460072, catid=250801, projectId=41427, created=2024-08-23 11:16:49, last-modified=2024-08-29 17:09:30
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/plugin/instanceModelCheck)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1460072)
 */
export function postPluginInstanceModelCheck(
  params: model.plugin.IPostPluginInstanceModelCheckParameter,
  options?: FlowHttpRequestOptions
) {
  return http.post<model.plugin.IPostPluginInstanceModelCheckResponse>('/webApi/plugin/instanceModelCheck', {
    data: pick(params, ['modelScoreList', 'modelTotalCount', 'config']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
