import { postUserLogout, postUserMe, postUserUpdateName, postUserUpdatePassword } from '@/api/modules/user';
import { Api, Spectra, SpectraModule } from '@/decorators';

@Spectra({ namespaced: true, name: 'Personal' })
export default class extends SpectraModule {
  @Api()
  userMe = postUserMe;
  @Api()
  userLogout = postUserLogout;
  @Api()
  userUpdateName = postUserUpdateName;
  @Api()
  userUpdatePassword = postUserUpdatePassword;
}
