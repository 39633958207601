import Vue from 'vue';
import Horn from '@mtfe/horn-sdk';

const options: any = {
  sankuaiOnly: false,
  isDev: process.env.CONFIG_ENV !== 'production',
};
Horn.init({}, options);

Vue.mixin({
  computed: {
    Horn() {
      return Horn;
    },
  },
});
declare module 'vue/types/vue' {
  interface Vue {
    Horn: any;
  }
}
