<template>
  <div class="top-nav">
    <div class="title">
      <div class="title-main  cursor-pointer" @click="jumpAgiEval('mvp-home')">AGI-Eval</div>
      <!-- <div v-if="themeId" class="title-sub">
        {{ themeDetail.theme?.name }}
      </div> -->
    </div>
    <AgiModal v-model="rankDeficient" :modalContent="rankDeficientCon">
      <template #btn>
        <div style="margin: 30px 0 10px">
          <mtd-button class="btn" @click="() => (rankDeficient = false)">好的</mtd-button>
        </div>
      </template>
    </AgiModal>
    <div v-if="routerInEval !== '/llmArena/instance/eval'" class="router">
      <div class="router-radio">
        <mtd-button
          :class="{ 'router-radio-button': true, 'router-radio-active': curPath === '/llmArena/home' }"
          @click="jump('/llmArena/home')"
        >
          <mtd-icon name="home-o" />
          首页
        </mtd-button>
        <mtd-dropdown ref="dropdown" :visible="visibleDropdown">
          <mtd-button
            :class="{ 'router-radio-button': true, 'router-radio-active': /^\/llmArena\/instance/.test(curPath) }"
            @click="visibleDropdownClick"
          >
            竞赛专区
          </mtd-button>
          <mtd-dropdown-menu slot="dropdown" class="menu" :append-to-container="false">
            <mtd-dropdown-menu-item v-for="item in instanceSubRoute" :key="item.path" class="menu-item">
              <mtd-popover trigger="click" placement="right-start" :show-arrow="false" popper-class="nav-theme-list">
                <div @click="dropdownClick"><mtd-icon :name="item.icon" />{{ item.label }}</div>
                <div v-if="visibleDropdown" slot="content" @click.stop="">
                  <div
                    v-for="themeItem in themeList?.themeList"
                    :key="themeItem.id"
                    class="nav-theme-list-item"
                    @click.stop="
                      (e) => {
                        visibleDropdownChange();
                        jump(`/llmArena/instance/${item.path}?themeId=${themeItem.id}`, { id: themeItem.id, path: item.path });
                      }
                    "
                  >
                    {{ themeItem.name }}
                  </div>
                </div>
              </mtd-popover>
            </mtd-dropdown-menu-item>
          </mtd-dropdown-menu>
        </mtd-dropdown>
        <mtd-dropdown trigger="click">
          <mtd-button :class="{ 'router-radio-button': true, 'router-radio-active': /^\/llmArena\/trouble/.test(curPath) }">
            疑难专区
          </mtd-button>
          <mtd-dropdown-menu slot="dropdown" class="menu" :append-to-container="false">
            <mtd-dropdown-menu-item v-for="item in troubleSubRoute" :key="item.path" class="menu-item">
              <div @click="jump(`/llmArena/trouble/${item.path}`)"><mtd-icon :name="item.icon" />{{ item.label }}</div>
            </mtd-dropdown-menu-item>
          </mtd-dropdown-menu>
        </mtd-dropdown>
        <mtd-button
          :class="{ 'router-radio-button': true, 'router-radio-active': curPath === '/llmArena/ranking/list' }"
          @click="jump('/llmArena/ranking/list')"
        >
          榜单专区
        </mtd-button>
        <mtd-dropdown class="profile-dropdown" trigger="click">
          <div class="profile-avatar">
            <mtd-button :class="{ 'router-radio-button': true, 'router-radio-active': curPath === '/llmArena/personal/center' }">
              个人中心
            </mtd-button>
          </div>
          <mtd-dropdown-menu slot="dropdown" class="menu">
            <mtd-dropdown-menu-item class="menu-item" @click="jump(`/llmArena/personal/ranking`)">
              <i class="mtdicon mtdicon-bar-chart" />
              我的排名
            </mtd-dropdown-menu-item>
            <mtd-dropdown-menu-item class="menu-item" @click="jump(`/llmArena/personal/center`)">
              <i class="mtdicon mtdicon-trophy" />
              我的奖励
            </mtd-dropdown-menu-item>
            <mtd-dropdown-menu-item class="menu-item">
              <i class="mtdicon mtdicon-smile-o" />
              <mtd-popover trigger="hover" placement="bottom">
                <span style="color: #fff; margin-left: 4px">我要提现</span>
                <template #content>
                  <div style="color: #fff; padding: 4px 14px">
                    <div><i class="mtdicon mtdicon-warning-circle-o" />暂未开放，将于比赛结束后开放；</div>
                    <div>请于首页关注比赛进程。</div>
                  </div>
                </template>
              </mtd-popover>
            </mtd-dropdown-menu-item>
          </mtd-dropdown-menu>
        </mtd-dropdown>
      </div>

      <div class="profile" @click="profile">
        <mtd-dropdown ref="profilemenu" class="profile-dropdown" trigger="click">
          <div class="profile-avatar">
            <i class="mtdicon mtdicon-avatar-fill avatar" />
          </div>
          <mtd-dropdown-menu slot="dropdown" class="menu">
            <mtd-dropdown-menu-item class="menu-item" @click.stop="">
              <div class="name-box">
                <i class="mtdicon mtdicon-avatar-fill" />
                <span class="name">{{ name }}</span>
              </div>
            </mtd-dropdown-menu-item>
            <mtd-dropdown-menu-item class="menu-item" @click="nicknameFlag = true">
              <i class="mtdicon mtdicon-edit-o" />
              修改昵称
            </mtd-dropdown-menu-item>
            <mtd-dropdown-menu-item class="menu-item" @click="goout">
              <i class="mtdicon mtdicon-file-import" />
              退出登录
            </mtd-dropdown-menu-item>
          </mtd-dropdown-menu>
        </mtd-dropdown>
      </div>
      <div v-if="nicknameFlag" class="nickname-box" @click="nicknameFlag = false">
        <div class="nickname">
          <mtd-input v-model="nickname" placeholder="请输入您的昵称" @click.stop="">
            <!-- <template #suffix></template> -->
          </mtd-input>
          <mtd-button class="nicknameSubmit" :loading="false" @click.stop="nicknameSubmit">提交</mtd-button>
        </div>
      </div>
    </div>
    <div v-else style="color: #b1b1b1; font-size: 18px">
      <i class="mtdicon mtdicon-warning-circle-o" />
      如需切换其他板块，请在提交后，点击弹窗中的“休息一下”
    </div>
  </div>
</template>
<script lang="ts">
import { postUserMe, postUserUpdateName } from '@/api/modules/user';
import AgiModal from '@/components/AgiModal.vue';
import { CatchError, Component, Prop, Ref, Spectra, Weaver } from '@/decorators';
import Person from '@/store/modules/Person';
import Theme from '@/store/modules/Theme';
import { ssoURL } from '@/utils/sso';
import axios from 'axios';

@Component({
  components: {
    AgiModal,
  },
})
@Spectra
export default class TopNav extends Weaver(Theme, Person) {
  @Ref('dropdown') dropdownRef: any;
  @Ref('profilemenu') profilemenuRef: any;
  @Prop()
  subTitle?: string;
  rankDeficient = false;
  nickname = '';
  nicknameFlag = false;
  rankDeficientCon = {
    bigHeadline: '<div>您当前等级不足</div><div>升级至LV.3 即可解锁出题板块</div>',
    imgUrl: 'https://p0.meituan.net/llmarena/21db7c675a71ffa63a9ca858750bbcde44356.png',
  };
  jumpAgiEval(target) {
    localStorage.setItem('man-machine-jump', 'MACHINE-TOP');
    window.open(
      this.$router.resolve({
        name: target,
      }).href,
      '_blank'
    );
  }

  get routerInEval() {
    return this.$route.path;
  }

  visibleDropdown = false;
  dropdownClick(e) {
    e.stopPropagation();
    this.visibleDropdown = true;
    this.action$themeList({});
  }

  visibleDropdownClick(e) {
    e.stopPropagation();
    this.visibleDropdown = !this.visibleDropdown;
  }

  @CatchError
  async nicknameSubmit() {
    if (!this.nickname) return this.$agitd.message.error('请输入新的昵称');
    await postUserUpdateName({ newName: this.nickname, userType: 'AGIEval' });
    this.$agitd.message.success('昵称修改成功！');
    this.closeChangeName();
  }

  closeChangeName() {
    this.nickname = '';
    this.nicknameFlag = false;
  }

  name = '-';
  userName = '';
  async profile() {
    const res:any = await postUserMe({});
    await this.$store.dispatch('getUserInfo');

    this.name = res?.data?.user?.name;
    this.userName = res?.data?.user?.mobileNumber || res?.data?.user?.mis;
    this.profilemenuRef?.updatePopper();
  }

  async goout() {
    const obj = {
      userName: this.userName,
    };
    await axios.post(`${ssoURL}/llmSso/logout`, obj);
    document.cookie = 'access-token=; max-age=0;';
    document.cookie = 'client-id=; max-age=0;';
    localStorage.removeItem('user');
    if (this.$route.name !== 'community-llmArena-home') {
      this.$router.push('/llmArena/home');
    }

    await this.$store.dispatch('setUserInfo');
  }

  get curPath() {
    return this.$route.path;
  }

  get themeId() {
    return Number(this.$route.query.themeId);
  }

  instanceSubRoute = [
    {
      path: 'detail',
      label: '我要出题',
      icon: 'question-circle-o',
    },

    {
      path: 'eval',
      label: '我要做题',
      icon: 'edit-o',
    },
  ];

  troubleSubRoute = [
    {
      path: 'overview',
      label: '题目概览',
      icon: 'list-view',
    },
  ];

  async jump(path, obj: any = {}) {
    if (obj.id && obj.path === 'detail') await this.action$getPersonUserInfo({ themeId: obj.id });
    if (path.includes('detail') && this.getPersonUserInfo.rank < 3) return (this.rankDeficient = true);
    this.$route.path !== path && this.$router.push(path);
  }

  visibleDropdownChange() {
    this.visibleDropdown = false;
  }

  mounted() {
    document.addEventListener('click', this.visibleDropdownChange);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.visibleDropdownChange);
  }
}
</script>
<style lang="less" scoped>
.name-box {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.base {
  white-space: nowrap;
}
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 4px;
}
::v-deep {
  .imgUrl {
    width: 230px !important;
    height: 230px !important;
  }
}
.nickname-box {
  position: fixed;
  inset: 0;
}
.nickname {
  position: absolute;
  right: 50px;
  top: 100px;
  // background: #00000080;
  // padding: 4px;
  border-radius: 6px;
  .nicknameSubmit {
    transform: translate(-38px);
    color: #000;
    border-radius: 111px;
    font-weight: 999;
    cursor: pointer;
  }
  .closeIcon {
    position: absolute;
    right: 16px;
    top: 4px;
    cursor: pointer;
    color: #ffff;
    font-size: 18px;
  }
  ::v-deep {
    .mtd-input-wrapper {
      background: rgba(0, 0, 0, 0) !important;
      border-radius: 400px;
      > input {
        background: rgba(0, 0, 0, 1) !important;
        border-radius: 400px;
        width: 200px;
        height: 40px;
        color: #fff;
        padding-left: 14px;
        padding-right: 70px;
        &.focus,
        &.hover {
          border-color: rgba(0, 0, 0, 0) !important;
          outline: none !important;
        }
      }
      .mtd-input.focus,
      .mtd-input.hover {
        border-color: rgba(0, 0, 0, 0) !important;
        outline: none !important;
      }
    }
  }
}
.btn {
  width: 130px;
  border-radius: 1000px;
  height: 46px;
  line-height: 46px;
  transform: translateX(5px);
  font-family: meituan type;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-right: 10px;
  &.ccc {
    background: #757575;
    color: #fff;
  }
}
.top-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  min-width: 1280px;
  top: 30px;
  padding: 0 50px;
  .title {
    &-main {
      font-size: 36px;
      line-height: 40px;
      font-family: 'Montserrat-Black';
      color: #ffd43b;
    }
    &-sub {
      font-size: 24px;
      line-height: 28px;
      font-family: 'meituan type';
      color: #ffd43b;
    }
  }
}
.router {
  display: flex;
  align-items: center;
  .profile {
    .profile-dropdown {
      width: 100%;
      height: 100%;
    }
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
    border: 1px solid #ffffffbe;
    .profile-avatar {
      width: 100%;
      height: 100%;
      display: flex;
      color: #ffffffbe;
    }
    .avatar {
      margin: auto;
      font-size: 30px;
    }
  }
  &-radio {
    height: 42px;
    border-radius: 21px;
    background-color: #ffffff1f;
    padding: 5px 10px;
    &-button {
      width: 130px;
      margin: 0 -5px;
      font-size: 16px;
      height: 32px;
      background-color: #ffffff00;
      border-radius: 16px;
      border: 0;
      color: white;
    }
    &-active {
      background-color: #00000080;
    }
  }
  .router-radio {
    display: flex;
    align-items: center;
  }
}

.menu {
  background: #000000c7;
  padding: 0;
  &-item {
    color: white;
  }
  &-item:hover {
    color: white;
    background: #ffffff33;
  }
}
.nav-theme-list {
  &-item {
    color: white;
    line-height: 36px;
    padding: 0 16px;
    cursor: pointer;
  }
  &-item:hover {
    background: #ffffff33;
  }
}
</style>
