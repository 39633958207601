<template>
  <div>
    <div
      class="top-nav-mobile"
      :style="{ justifyContent: ($route.name?.includes('home') || $route.name?.includes('mobileHome')) ? 'end' : 'space-between' }"
    >
      <div
        v-if="!$route.name?.includes('home') && !$route.name?.includes('mobileHome')"
        class="back"
        @click="back"
      >
        <i class="mtdicon mtdicon-left left" />
        <b>{{ $route.meta.title }}</b>
      </div>
      <div class="top-set" @click="profile">
        <mtd-dropdown ref="profilemenu" class="profile-dropdown" trigger="click">
          <div class="profile-avatar">
            <i
              class="mtdicon mtdicon-setting avatar"
              :style="{ color: $route.name?.includes('home') || $route.name?.includes('mobileHome') ? '' : '#ffd43b' }"
            />
          </div>
          <mtd-dropdown-menu slot="dropdown" class="menu">
            <mtd-dropdown-menu-item class="menu-item" @click.stop="">
              <div class="mobile-name-box">
                <i class="mtdicon mtdicon-avatar-fill" />
                <span class="name">{{ userMes?.user.name }}</span>
              </div>
            </mtd-dropdown-menu-item>
            <mtd-dropdown-menu-item class="menu-item" @click="nicknameFlag = true">
              <i class="mtdicon mtdicon-edit-o" />
              修改昵称
            </mtd-dropdown-menu-item>
            <mtd-dropdown-menu-item class="menu-item" @click="goout">
              <i class="mtdicon mtdicon-file-import" />
              退出登录
            </mtd-dropdown-menu-item>
          </mtd-dropdown-menu>
        </mtd-dropdown>
      </div>
    </div>
    <AgiModal v-model="nicknameFlag" class="unPc" :modalContent="isUnPCCon" :close="() => nicknameFlag = false" :setName="true">
      <template #btn>
        <div class="nickname">
          <mtd-input v-model="nickname" class="nickname-input" placeholder="请输入您的新昵称" @click.stop=""/>
          <mtd-button class="nicknameSubmit" :loading="false" @click.stop="nicknameSubmit">提交</mtd-button>
        </div>
      </template>
    </AgiModal>
    <AgiModal v-model="backFlag" class="unPc" :modalContent="backContent" :close="() => backFlag = false">
      <template #btn>
        <div>
          <mtd-button class="btn" @click.stop="backFlag = false">返回对话</mtd-button>
        </div>
      </template>
    </AgiModal>
  </div>
</template>
<script lang="ts">
import AgiModal from '@/components/AgiModal.vue';
import { CatchError, Component, Spectra, Weaver, Ref } from '@/decorators';
import { postUserLogout, postUserUpdateName } from '@/api/modules/user';
import Common from '@/store/modules/Common';

@Component({
  components: {
    AgiModal,
  },
})
@Spectra
export default class App extends Weaver(Common) {
  @Ref('profilemenu') profilemenuRef: any;

  get issueStatus() {
    return this.$store.state.issueUnlock;
  }

  backFlag = false;
  backContent = {
    bigImgUrl: 'https://p0.meituan.net/llmarena/0b0b5399f06c03b503737c8b4365c65030272.png',
    bigHeadlineList: ['如果现在退出，可能会', '影响积分奖励噢！'],
  };

  back() {
    if (!this.$route.meta) return;
    const tenMinQuiz = this.$route.name?.includes('tenMinQuiz');
    if (!tenMinQuiz) {
      this.$route.meta.parent ? this.$router.push(this.$route.meta.parent) : this.$router.back();
    } else if (tenMinQuiz && this.issueStatus.flag) {
      this.$router.push(this.$route.meta.parent);
    } else {
      this.backFlag = true;
    }
  }

  nicknameFlag = false;
  nickname = '';
  isUnPCCon = {
    bigHeadline: '<div>请输入新昵称</div>',
  };
  async profile() {
    await this.action$userMes({});
    this.profilemenuRef?.updatePopper();
  }
  @CatchError
  async nicknameSubmit() {
    if (!this.nickname) return this.$agitd.message.error('请输入新的昵称');
    await postUserUpdateName({ newName: this.nickname, userType: 'AGIEval' });
    this.$agitd.message.success('修改昵称成功');
    this.closeChangeName();
    await this.action$userMes({});
  }
  closeChangeName() {
    this.nickname = '';
    this.nicknameFlag = false;
  }
  goout() {
    if (!this.issueStatus.flag) return this.backFlag = true;
    postUserLogout({}).then(() => {
      localStorage.removeItem('user');
      this.$router.push('/llmArena/login');
    });
  }
  styleTag = null as any;
  mounted() {
    this.styleTag = document.createElement('style');
    this.styleTag.type = 'text/css';
    this.styleTag.innerHTML = `
      .mtd-popper {
        background: #000;
      }
      .mtd-popper .menu .menu-item {
        color: #fff;
      }
      .mobile-name-box {
        max-width: 100px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    `;
    document.head.appendChild(this.styleTag);
  }

  beforeDestroy() {
    if (this.styleTag) document.head.removeChild(this.styleTag);
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/common.scss';

.top-nav-mobile {
  width: 100%;
  position: fixed;
  top: pxvw(26);
  left: 0;
  padding: 0 pxvw(20);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .back {
    color: #ffd43b;
    font-weight: 999;
    ::v-deep {
      .mtdicon-left::before {
        font-weight: 999;
      }
    }
  }
  .top-set {
    .avatar {
      font-size: pxvw(30);
    }
  }
  .left {
    font-size: pxvw(16);
  }
  b {
    font-size: pxvw(16);
  }
}

.btn {
  width: pxvw(120);
  border-radius: pxvw(1000);
  height: pxvw(44);
  transform: translateX(pxvw(5));
  font-family: meituan type;
  font-size: pxvw(18);
  font-weight: 400;
  text-align: center;
  margin: pxvw(20) 0;
  margin-right: pxvw(10);
  &.ccc {
    background: #757575;
    color: #fff;
  }
}

.nickname {
  position: relative;
  width: 100%;
  height: pxvw(44);
  border-radius: pxvw(6);
  .nicknameSubmit {
    color: #000;
    border-radius: pxvw(111);
    font-weight: 999;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 50%;
    height: pxvw(36);
    font-size: pxvw(16);
    width: pxvw(80);
    transform: translate(pxvw(-4), -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .closeIcon {
    position: absolute;
    right: pxvw(16);
    top: pxvw(4);
    cursor: pointer;
    color: #ffff;
    font-size: pxvw(18);
  }
  ::v-deep {
    .mtd-input-wrapper {
      background: rgba(0, 0, 0, 0) !important;
      border-radius: pxvw(400);
      width: 100%;
      > input {
        background: #3a3c3d !important;
        border-radius: pxvw(400);
        width: 100%;
        height: pxvw(44);
        color: #fff;
        padding-left: pxvw(14);
        padding-right: pxvw(86);
        &.focus,
        &.hover {
          border-color: rgba(0, 0, 0, 0) !important;
          outline: none !important;
        }
      }
      .mtd-input.focus,
      .mtd-input.hover {
        border-color: rgba(0, 0, 0, 0) !important;
        outline: none !important;
      }
    }
    .mtd-input.hover, .mtd-input:hover, .mtd-textarea.hover, .mtd-textarea:hover {
        border-color: rgba($color: #000000, $alpha: 0);
    }
  }
}

.name {
  margin-left: pxvw(4);
}
</style>
