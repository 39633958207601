import {
  postUserForgetPassword,
  postUserGenerateCaptcha,
  postUserLoginByPassword,
  postUserLoginBySmsCode,
  postUserLogout,
  postUserMe,
  postUserRegister,
  postUserSendSmsVerifyCode,
  postUserUpdateName,
  postUserUpdatePassword,
} from '@/api/modules/user';
import { Api, ApiAction, Spectra, SpectraModule } from '@/decorators';
import Cookies from 'js-cookie';
import store from '..';

@Spectra({ namespaced: true, name: 'User' })
export default class extends SpectraModule {
  @Api()
  captcha = postUserGenerateCaptcha;

  @ApiAction()
  register = async ({ smsVerifyCode, mobileNumber, password, userType }) => {
    const res = (await postUserRegister({ smsVerifyCode, mobileNumber, password, userType })) as any;
    this.setSessionId(res);
  };
  @ApiAction()
  loginByPassword = async ({ eventUniqueId, captchaVerifyCode, mobileNumber, password }) => {
    const res = (await postUserLoginByPassword({ eventUniqueId, captchaVerifyCode, mobileNumber, password })) as any;
    this.setSessionId(res);
  };
  @ApiAction()
  updatePassword = postUserUpdatePassword;
  @ApiAction()
  loginBySmsCode = async (params) => {
    const res: any = await postUserLoginBySmsCode(params);
    this.setSessionId(res);
  };
  @ApiAction()
  sendSmsVerifyCode = postUserSendSmsVerifyCode;
  @ApiAction()
  logout = postUserLogout;
  @ApiAction()
  updateName = postUserUpdateName;

  setSessionId({ sessionId }) {
    Cookies.set('arena_user_session_id', sessionId, { expires: 30 });
    store.dispatch('getUserInfo');
  }

  @ApiAction()
  userForgetPassword = postUserForgetPassword;
  @Api()
  userMe = postUserMe;
}
