import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1457185, catid=250712, projectId=41427, created=2024-08-21 16:02:52, last-modified=2024-08-29 16:43:23
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/theme/list)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457185)
 */
export function postThemeList(params: model.theme.IPostThemeListParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.theme.IPostThemeListResponse>('/webApi/theme/list', {
    data: params,
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}

/**
 * @desc id=1457500, catid=250712, projectId=41427, created=2024-08-22 15:21:12, last-modified=2024-08-29 16:47:24
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/webApi/theme/get)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1457500)
 */
export function postThemeGet(params: model.theme.IPostThemeGetParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.theme.IPostThemeGetResponse>('/webApi/theme/get', {
    data: pick(params, ['id']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
