import {
  postInstanceEvalAnswer,
  postInstanceEvalComplete,
  postInstanceEvalDetail,
  postInstanceEvalDispatch,
  postInstanceEvalMyThemeInfo,
  postInstanceEvalSwitchModelReminderClose,
} from '@/api/modules/instanceEval';
import { Api, Spectra, SpectraModule } from '@/decorators';

@Spectra({ namespaced: true, name: 'InstanceEval' })
export default class extends SpectraModule {
  @Api()
  myThemeInfo = postInstanceEvalMyThemeInfo;
  @Api({
    defaultState: {
      instance: {},
      currentModel: {},
      abortInstanceEvalList: [],
    },
  })
  dispatchedInstance = postInstanceEvalDispatch;
  @Api()
  answerResult = postInstanceEvalAnswer;
  @Api() switchModelReminderClose = postInstanceEvalSwitchModelReminderClose;
  @Api() instanceEvalComplete = postInstanceEvalComplete;
  @Api() instanceEvalDetail = postInstanceEvalDetail;
}
