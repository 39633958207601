import { quiz } from '@/api/index';
import { Api, Spectra, SpectraModule } from '@/decorators';

@Spectra({ namespaced: true, name: 'Quiz' })
export default class extends SpectraModule {
  @Api()
  quizGetEvalList = quiz.postQuizGetEvalList;
  @Api()
  quizGetEvalDetail = quiz.postQuizGetEvalDetail;
  @Api()
  quizGetProgress = quiz.postQuizGetProgress;
  @Api()
  quizSetRemindStatus = quiz.postQuizSetRemindStatus;
  @Api()
  quizListEvalSubject = quiz.postQuizListEvalSubjectConfig;
  @Api()
  quizCreateEval = quiz.postQuizCreateEval;
  @Api()
  quizEnterQuiz = quiz.postQuizEnterQuiz;
  @Api()
  quizDialogueBehaviorRecord = quiz.postQuizDialogueBehaviorRecord;
  @Api()
  quizGetRunningEval = quiz.postQuizGetRunningEval;
  @Api()
  quizChatLogFeedback = quiz.postQuizChatLogFeedback;
  @Api()
  quizEvalFail = quiz.postQuizEvalFail;
}
