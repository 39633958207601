import { postUserMe } from '@/api/modules/user';
import Vue from 'vue';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: false })
export default class Main extends VuexModule {
  role = {
    id: -1,
    mobileNumber: '',
    name: '',
    score: -1,
    status: '',
  };
  globalConfig = {
    currentSeason: {
      id: -1,
      name: '',
    },
  };
  issueUnlock = {
    flag: true,
  };

  @Mutation
  issueUnlockChange(role) {
    this.issueUnlock = {
      ...this.issueUnlock,
      ...role,
    };
  }

  @Mutation
  roleChange(role) {
    this.role = role;
  }

  intoMobileHome = false;

  @Mutation
  intoMobileHomeChange(val) {
    this.intoMobileHome = val;
  }

  loadingMap: { [k: string]: unknown } = {};
  requestMap: { [k: string]: Request } = {};

  @Mutation
  setRequest(payload: { actionName: string; loading: boolean; request: Request }) {
    const { actionName, loading, request } = payload;
    Vue.set(this.loadingMap, actionName, loading);
    Vue.set(this.requestMap, actionName, request);
  }

  @Action
  async getUserInfo() {
    try {
      const res: any = await postUserMe({});
      this.context.commit('roleChange', { ...this.role, ...res.data.user } as any);
    } catch (e) {
      console.log('获取用户信息失败!', e);
    }
  }

  @Action
  async setUserInfo() {
    try {
      const role = {
        id: -1,
        mobileNumber: '',
        name: '',
        score: -1,
        status: '',
      };
      this.context.commit('roleChange', { ...this.role, ...role } as any);
    } catch (e) {
      console.log('获取用户信息失败!', e);
    }
  }
}
