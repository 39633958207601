import { postThemeGet, postThemeList } from '@/api/modules/theme';
import { Spectra, SpectraModule, Api } from '@/decorators';

@Spectra({ namespaced: true, name: 'Theme' })
export default class extends SpectraModule {
  @Api({ defaultState: { themeList: [] } })
  themeList = postThemeList;
  @Api({ defaultState: {
    theme: {
      id: 0,
      name: '',
      originInputRequirement: '',
      correctAnswerRequirement: '',
    },
  } })
  themeDetail = postThemeGet;
}
